const initialState = {
  discounts: [],
  isLoading: true,
}

const discountsData = (state = initialState, action) => {
  switch(action.type) {
    case "SET_DISCOUNTS_DATA":
      return { ...state, discounts: action.value, isLoading: false, };
    default:
      return state;
  }
}

export default discountsData;
