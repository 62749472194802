// Totals everything needed for orders/transactions
export function cartTotals(cart, transaction) {


  //Cart total should total different whether it's a transaction or it's in state.
  cart = {...cart}

  let subTotal = 0;
  let total = 0;
  let totalPaid = 0
  let discounts = 0
  let delivery = 0
  let change = 0
  let tips = 0
  let totalRemaining = 0
  let tax = 0
  let refund = 0

  const customerData = Object.keys(cart.customer)

  // Ads Item Modifer Prices

    // Finds Sub Total
    cart.items.map(item => subTotal += item.variant ?
      item.damount ? item.dtype == "amount" ? item.variant.price * item.quantity - item.damount : (item.variant.price * item.quantity) - ((item.variant.price * item.quantity) / 100 * item.damount)
      : item.variant.price * item.quantity
      : item.damount ? item.dtype == "amount" ? item.price * item.quantity - item.damount : (item.price * item.quantity) - ((item.price * item.quantity) / 100 * item.damount)
      : item.price * item.quantity
    );

    cart.items.map(item => {
      if (typeof item.modifiers != "undefined" && item.modifiers.length > 0) {
        item.modifiers.map(modifier => {
          subTotal += modifier.price * item.quantity

          // Calculate total inclusive tax for each modifier
          if (modifier.tax != undefined) {
            let price = modifier.price;
            let quantity = item.quantity;

            // Adjust the price to find the original price without tax
            let originalPriceWithoutTax = price / (1 + modifier.tax.rate);

            // Calculate the tax amount based on the original price without tax
            let modifierTax = originalPriceWithoutTax * modifier.tax.rate;

            tax += modifierTax * quantity; // Multiply by quantity if applicable
          }
        })
      }
    })

  // Calculate total inclusive tax for each item in the cart
  cart.items.map(item => {
    if (item.tax != undefined) {
        let price = item.variant ? item.variant.price : item.price;
        let quantity = item.quantity;
        let discountAmount = item.damount ? item.damount : 0;

        if (item.dtype == "amount") {
            discountAmount *= quantity;
        } else {
            discountAmount = (price * quantity) * (discountAmount / 100);
        }

        let taxableAmount = (price * quantity) - discountAmount;
        let itemTax = taxableAmount / (1 + item.tax.rate) * item.tax.rate;
        tax += itemTax;
    }
  });

  // Automatic Discounts
  // Loop through each applied discount and calculate the discount value
  cart.automaticDiscounts?.forEach(discount => {

    const eligibleItems = cart.items.filter(item => 
      (!discount.condition.item_ids || discount.condition.item_ids.length === 0 || discount.condition.item_ids.includes(item.pid)) &&
      (!discount.condition.category_ids || discount.condition.category_ids.length === 0 || discount.condition.category_ids.includes(item.category))
    );

    const eligibleItemCount = eligibleItems.reduce((sum, item) => sum + item.quantity, 0);
    const eligibleSubTotal = eligibleItems.reduce((sum, item) => sum + item.price * item.quantity, 0);

    if (discount.condition.per_min_items && discount.condition.type === 'item_count') {
      const numDiscounts = Math.floor(eligibleItemCount / discount.condition.min_items);
      if (discount.type === 'percentage') {
        const discountSubtotal = eligibleSubTotal / eligibleItemCount * discount.condition.min_items;
        discounts += (discountSubtotal * (discount.amount / 100)) * numDiscounts;
      } else if (discount.type === 'amount') {
        discounts += discount.amount * numDiscounts;
      }
    } else {
      if (discount.type === 'percentage') {
        discounts += eligibleSubTotal * (discount.amount / 100);
      } else if (discount.type === 'amount') {
        discounts += discount.amount;
      }
    }
  });


  // Order Discounts
  if (cart.discount.type == "percentage") {
    discounts = discounts + (subTotal * (cart.discount.amount / 100))
  } else if (cart.discount.type == "amount") {
    discounts = discounts + cart.discount.amount
  }

  total = subTotal - discounts

  // Adds Order delivery - Temp fix for now
  if (cart.customer.location != undefined && cart.customer.location != null && Object.keys(cart.customer.location).length > 0) {
    // Check if location is an array and access the price accordingly
    const location = Array.isArray(cart.customer.location) ? cart.customer.location[0] : cart.customer.location;

    if (location.price != undefined) {
        delivery = location.price;
        total = total + delivery;
    }
  }
  // Adds total paid
  cart.payments?.map(payment => totalPaid += payment.amount);

  // Adds Payments Change
  cart.payments?.map(item => change += item.change != undefined ? item.change : 0)

  // Adds Payments Tips
  cart.payments?.map(item => tips += item.tipAmount != undefined ? item.tipAmount : 0)
  total = total + tips
  totalPaid = totalPaid + tips

  totalRemaining = total - totalPaid

  //Refund
  refund = cart?.refundValue || 0

  // Rounds totals up so they're nice
  subTotal = Math.round((subTotal + Number.EPSILON) * 100) / 100
  total = Math.round((total + Number.EPSILON) * 100) / 100
  totalPaid = Math.round((totalPaid + Number.EPSILON) * 100) / 100
  discounts = Math.round((discounts + Number.EPSILON) * 100) / 100
  delivery = Math.round((delivery + Number.EPSILON) * 100) / 100
  change = Math.round((change + Number.EPSILON) * 100) / 100
  tips = Math.round((tips + Number.EPSILON) * 100) / 100
  totalRemaining = Math.round((totalRemaining + Number.EPSILON) * 100) / 100
  tax = Math.round((tax + Number.EPSILON) * 100) / 100
  refund = Math.round((refund + Number.EPSILON) * 100) / 100

  return { subTotal, total, totalPaid, discounts, delivery, change, tips, totalRemaining, tax, refund }
}
