import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

// Grabs reducers from Folder.

import cartData from '../reducers/cartData'
import itemData from '../reducers/itemData'
import ordersData from '../reducers/ordersData'
import branchesData from '../reducers/branchesData'
import deliveriesData from '../reducers/deliveriesData'
import usersData from '../reducers/usersData'
import discountsData from '../reducers/discountsData'

// Combines Reducers.

const rootReducer = combineReducers({
  branchesData,
  cartData,
  itemData,
  deliveriesData,
  ordersData,
  usersData,
  discountsData
})

// Create Store with Combined Reducers

const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware),
)


// Export

export default store
