
import { createStore, applyMiddleware, useSelector } from 'redux';
import thunkMiddleware from 'redux-thunk';

import * as firebase from 'firebase';
import 'firebase/firestore';

// Set Orders Data

const setDiscountsData = (deliveriesData) => {
  return {
    type: "SET_DISCOUNTS_DATA",
    value: deliveriesData,
  }
};

const watchDiscountsData = (deliveriesData, company) => {

  return function(dispatch) {
    global.backendFirebase.firestore()
        .collection("/discounts/" + global.companyId + "/discount/")
        .where("branch", "==", global.branchId)
        .where("isOnline", "==", true)
        .where("active", "==", true)
        .onSnapshot(querySnapshot => {
          const data = querySnapshot.docs.map(doc => {
            const id = doc.id
            const data = doc.data()
            return Object.assign({id, ...data})
          });
          var actionSetDeliveriesData = setDiscountsData(data);
          dispatch(actionSetDeliveriesData);
        });
  }
};


export { setDiscountsData, watchDiscountsData };
