import * as React from 'react';
import { View, ScrollView, StyleSheet, ActivityIndicator, Text, Button, TouchableOpacity, FlatList } from 'react-native';

import { useNavigation } from '@react-navigation/native'

import { connect, useDispatch } from 'react-redux';
import * as firebase from 'firebase';
import 'firebase/firestore';

import moment from "moment";

import { selectBranch } from '../api/branches';

import { setOrdersData, watchOrdersData, setOrdersDetailsData, watchOrdersDetailsData } from './../api/ordersData'
import { setUsersData, watchUsersData } from './../api/usersData'


import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts';
import { cartTotals } from '../helpers/cartTotals';


function FormatOrderNumber(num) {
    var r = "" + num;
    while (r.length < 3) {
        r = "0" + r;
    }
    return r;
}

const mapStateToProps = (state) => {
  return {
    ordersData: state.ordersData.ordersData,
    ordersDataIsLoading: state.ordersData.isLoading,
    ordersDetailsData: state.ordersData.orderDetailsData,
    usersData: state.usersData,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    watchOrdersData: () => dispatch(watchOrdersData()),
    watchOrdersDetailsData: () => dispatch(watchOrdersDetailsData()),
    watchUsersData: () => dispatch(watchUsersData()),
  };
}


class ViewOrderScreen extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
        isLoading: true,
        user: true,
      }
  }

  async componentDidMount() {

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ user: true })

        await this.props.watchUsersData()

        setTimeout(async () => {
          await this.props.watchOrdersData()
          await this.props.watchOrdersDetailsData()
          this.setState({ isLoading: false })
        }, 800);
      } else {
        this.setState({ user: false, isLoading: false })
        this.props.navigation.navigate('Login')
      }
    })
  }

  renderSeparatorView = () => {
    return (
      <View style={{
          height: 1,
          width: "100%",
          backgroundColor: colors.border,
        }}
      />
    );
  };

  renderOrdersList = ( { item }) => {
    return (
      <OrderItem order={item} />
    )
  }

  renderOrder = (ordersData) => {

    let order = ordersData.filter(orders => orders.id === this.props.route.params.id.replace("View-", ""))

    if (order.length > 0) {

    order = order[0]

    var timeCreated = new Date()
    var timeReady = new Date()

    if (order.timeCreated != null) {
      timeCreated = moment(new Date(order.timeCreated.seconds * 1000)).format("DD/MM/YY - HH:mm")
    }

    if (order.timeSlot != null) {
      timeReady = moment(new Date(order.timeSlot.seconds * 1000)).format("DD/MM/YY - HH:mm")
    }

    let orderDetails = this.props.ordersDetailsData.filter(details => details.order == order.id)

    let { subTotal, total, totalPaid, discounts, delivery, change, tips, totalRemaining, tax } = cartTotals({ ...order, items: [ ...orderDetails ], payments: []}, 0)


    return (
      <View style={styles.main}>
        <View style={styles.header}>
          <View style={styles.container}>
            <Text style={{color: colors.secondary, fontFamily: fonts.bold, fontSize: 36}}>#{FormatOrderNumber(order.orderNo)}</Text>
            <Text style={{color: colors.secondary, fontSize: 14, fontFamily: fonts.regular}}>{order.status == 1 ? "Awaiting Confirmation" : order.status == 2 ? "In Kitchen" : order.status == 3 ? "Ready/In Transit" : "Cancelled"}</Text>
          </View>
        </View>

        <ScrollView style={styles.container}>

          <View style={styles.section}>

          <View
            style={{ paddingTop: 5, paddingBottom: 16, flexDirection: "row"}}>
            <Text style={{ flex: 1, flexDirection: "row", fontFamily: fonts.bold}}>{order.type == 1 ? "Delivery" : order.type == 2 ? "Collection" : "Unknown"}</Text>
                    {order.type == 1 ?
                      <Text style={{ flex: 2, textAlign: "right"}}>
                        {order.customer.line1 ? order.customer.line1 + ", " : null}
                        {order.customer.line2 ? order.customer.line2 + ", " : null}
                        {order.customer.town ? order.customer.town + ", " : null}
                        {order.customer.postcode ? order.customer.postcode + "." : null}
                      </Text>
                    : null}
          </View>

          <View
            style={{ paddingTop: 5, paddingBottom: 16, flexDirection: "row"}}>
            <Text style={{ flex: 1, flexDirection: "row", fontFamily: fonts.bold}}>Ordered</Text>
                <Text style={{ flex: 2, textAlign: "right", fontFamily: fonts.regular}}>
                    {timeCreated}
                  </Text>
          </View>

          <View
            style={{ paddingTop: 5, paddingBottom: 16, flexDirection: "row"}}>
            <Text style={{ flex: 1, flexDirection: "row", fontFamily: fonts.bold}}>Ready for</Text>
                <Text style={{ flex: 2, textAlign: "right", fontFamily: fonts.regular}}>
                    {timeReady}
                  </Text>
          </View>

          {order.note ?
            <View
              style={{ paddingTop: 5, paddingBottom: 16, flexDirection: "row"}}>
              <Text style={{ flex: 1, flexDirection: "row", fontFamily: fonts.bold}}>Notes</Text>
                  <Text style={{ flex: 2, textAlign: "right", fontFamily: fonts.regular}}>
                      {order.note}
                    </Text>
            </View>
          : null}
        </View>


        <View style={styles.section}>
          <Text style={styles.sectionHeader}>Order</Text>
          <View style={{}}>
          {orderDetails.map((item, index) => {
            return (
            <View
              style={{ paddingTop: 15, paddingBottom: 16, flexDirection: "row"}}
              key={index}>
              <View style={{ flex: 2, flexDirection: "row"}}>
                <Text style={{ fontSize: 14, fontFamily: fonts.bold, color: colors.text, backgroundColor: 'rgba(100, 100, 100, 0.1)', paddingVertical: 4 , paddingHorizontal: 8, marginTop: -5, marginRight: 10}}>{item.quantity}</Text>
                <Text style={{fontSize: 14, fontFamily: fonts.regular}} numberOfLines={1}>{item.name}</Text>
              </View>
              <Text style={{ flex: 0.5, textAlign: "right", fontFamily: fonts.regular}}>£{(+item.price).toFixed(2)}</Text>
            </View>
          )})}
          </View>

          <View style={{ borderTopWidth: 1, borderTopColor: colors.border, marginTop: 15}}>
            <View
              style={{ paddingTop: 20, paddingBottom: 16, flexDirection: "row"}}
            >
              <View style={{ flex: 2, flexDirection: "row"}}>
                <Text style={{fontSize: 14, fontFamily: fonts.bold}} numberOfLines={1}>Subtotal</Text>
              </View>
              <Text style={{ flex: 0.5, textAlign: "right", fontFamily: fonts.regular}}>£{(+subTotal).toFixed(2)}</Text>
            </View>

            {order.type == 1 ?
              <View
                style={{ paddingTop: 5, paddingBottom: 16, flexDirection: "row"}}
              >
                <View style={{ flex: 2, flexDirection: "row"}}>
                  <Text style={{fontSize: 14, fontFamily: fonts.bold}} numberOfLines={1}>{order.customer.location.name} Delivery</Text>
                </View>
                <Text style={{ flex: 0.5, textAlign: "right", fontFamily: fonts.regular}}>£{(+order.customer.location.price).toFixed(2)}</Text>
              </View>
            : null}

            {discounts > 0 ?
              <View
                style={{ paddingTop: 5, paddingBottom: 16, flexDirection: "row"}}
              >
                <View style={{ flex: 2, flexDirection: "row"}}>
                  <Text style={{fontSize: 14, fontFamily: fonts.bold}} numberOfLines={1}>Discount</Text>
                </View>
                <Text style={{ flex: 0.5, textAlign: "right", fontFamily: fonts.regular}}>-£{(+discounts).toFixed(2)}</Text>
              </View>
            : null}
            <View
              style={{ paddingTop: 5, paddingBottom: 16, flexDirection: "row"}}
            >
              <View style={{ flex: 2, flexDirection: "row"}}>
                <Text style={{fontSize: 14, fontFamily: fonts.bold}} numberOfLines={1}>Total</Text>
              </View>
              <Text style={{ flex: 0.5, textAlign: "right", fontFamily: fonts.regular}}>£{(+total).toFixed(2)}</Text>
            </View>

          </View>
        </View>
        </ScrollView>
      </View>
    )
  } else {
    return (
      <View style={styles.loadingContainer}>
        <Text style={{ fontFamily: fonts.regular, color: colors.text }}>Sorry, we're unable to find this order.</Text>
      </View>
    )
  }
  }

  render() {
    if (!this.state.user && this.state.isLoading == false) {
      return (
        <View style={styles.loadingContainer}>
          <Text style={{ fontFamily: fonts.regular, color: colors.text }}>Sorry, you must be logged in to view this order.</Text>
        </View>
      )
    } else if (this.props.ordersDataIsLoading) {
      return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator
          color={colors.primary}
        />
      </View>
    )
  } else {
      return (
        this.renderOrder(this.props.ordersData)
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrderScreen);

const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: colors.white,
  },
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    alignSelf: 'center',
    width: "100%",
    maxWidth: 1280,
    paddingHorizontal: 30,
  },
  text: {
    color: colors.text,
    padding: 20,
  },
  header: {
    height: 120,
    paddingTop: 20,
    backgroundColor: colors.primary,
  },
  section: {
    paddingVertical: 20,
  },
  sectionHeader: {
    fontSize: 24,
    paddingBottom: 20,
    fontFamily: fonts.bold
  }
});
