import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//import discountsData from './discounts.json'; // This is temp

// Actions to add and remove discounts
const addAutomaticCartDiscount = (discounts) => ({
  type: 'ADD_AUTOMATIC_DISCOUNT',
  payload: discounts
});

const removeAutomaticCartDiscount = (discounts) => ({
  type: 'REMOVE_AUTOMATIC_DISCOUNT',
  payload: discounts
});

const removeUserRemovedDiscount = (discountId) => ({
  type: 'REMOVE_USER_REMOVED_DISCOUNT',
  payload: discountId
});

const addUserRemovedDiscount = (discountId) => ({
  type: 'ADD_USER_REMOVED_DISCOUNT',
  payload: discountId
});

// Use Discounts finds autoamtic discounts for items that could be applied and then adds them to the order cart.
const useDiscounts = (subTotal) => {
  const dispatch = useDispatch();
  const items = useSelector(state => state.cartData.items);
  const discounts = useSelector(state => state.discountsData.discounts);
  const appliedDiscounts = useSelector(state => state.cartData.automaticDiscounts);
  const userRemovedDiscounts = useSelector(state => state.cartData.userRemovedDiscounts);

  useEffect(() => {
    let newAppliedDiscounts = [];

    const autoDiscounts = discounts.filter(discount => discount.automatic);

    const checkCondition = (cartItems, condition) => {
      // If no item_ids or category_ids, consider the whole cart eligible
      const eligibleItems = cartItems.filter(item =>
        (!condition.item_ids || condition.item_ids.length === 0 || condition.item_ids.includes(item.pid)) &&
        (!condition.category_ids || condition.category_ids.length === 0 || condition.category_ids.includes(item.category))
      );
    
      if (condition.type === 'item_count') {
        if (condition.sameItem) {
          return eligibleItems.some(item => item.quantity >= condition.min_items);
        } else {
          const totalItemCount = eligibleItems.reduce((sum, item) => sum + item.quantity, 0);
          return totalItemCount >= condition.min_items;
        }
      } else if (condition.type === 'order_total') {
        // Calculate total for eligible items or the whole cart
        const eligibleItemsTotal = eligibleItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
        return eligibleItemsTotal >= condition.min_total;
      }
    
      return false;
    };
    

    const calculateApplicableDiscounts = (cartItems, discountList) => {
      let applicableDiscounts = [];

      discountList.forEach(discount => {
        if (discount.condition && checkCondition(cartItems, discount.condition)) {
          applicableDiscounts.push(discount);
        }
      });

      return applicableDiscounts;
    };

    const applyHighestPriorityDiscount = (cartItems, discountList) => {
      let applicableDiscounts = calculateApplicableDiscounts(cartItems, discountList);
    
      applicableDiscounts.sort((a, b) => b.amount - a.amount);
    
      let processedItems = [...cartItems]; // Make a copy of cart items
    
      applicableDiscounts.forEach(discount => {
        // Check for eligible items or apply to the whole cart if no specific conditions
        const eligibleItems = processedItems.filter(item =>
          (!discount.condition.item_ids || discount.condition.item_ids.length === 0 || discount.condition.item_ids.includes(item.pid)) &&
          (!discount.condition.category_ids || discount.condition.category_ids.length === 0 || discount.condition.category_ids.includes(item.category))
        );
    
        if (checkCondition(eligibleItems.length > 0 ? eligibleItems : processedItems, discount.condition)) {
          newAppliedDiscounts.push(discount);
    
          const discountQuantity = Math.floor(eligibleItems.reduce((sum, item) => sum + item.quantity, 0) / discount.condition.min_items);
          let itemsToReduce = discountQuantity * discount.condition.min_items;
    
          processedItems = processedItems.map(item => {
            if (itemsToReduce > 0) {
              if (item.quantity > itemsToReduce) {
                itemsToReduce -= item.quantity;
                return { ...item, quantity: item.quantity - itemsToReduce };
              } else {
                itemsToReduce -= item.quantity;
                return { ...item, quantity: 0 };
              }
            }
            return item;
          });
        }
      });
    };
    

    applyHighestPriorityDiscount(items, autoDiscounts);

    // Remove any discounts that the user has opted out of
    newAppliedDiscounts = newAppliedDiscounts.filter(
      discount => !userRemovedDiscounts.includes(discount.id)
    );

    // Find discounts to be removed
    const discountsToRemove = appliedDiscounts.filter(
      discount => !newAppliedDiscounts.some(newDiscount => newDiscount.id === discount.id)
    );

    // Find new discounts to be added
    const discountsToAdd = newAppliedDiscounts.filter(
      discount => !appliedDiscounts.some(appliedDiscount => appliedDiscount.id === discount.id)
    );

    // Dispatch actions only if there are changes
    if (discountsToAdd.length > 0) {
      dispatch(addAutomaticCartDiscount(discountsToAdd));
    }

    if (discountsToRemove.length > 0) {
      dispatch(removeAutomaticCartDiscount(discountsToRemove));
    }

    // Remove discounts from userRemovedDiscounts if the cart length decreases
    userRemovedDiscounts.forEach(discountId => {
      const discount = discounts.find(d => d.id === discountId);
      if (discount && !checkCondition(items, discount.condition)) {
        dispatch(removeUserRemovedDiscount(discountId));
      }
    });

  }, [items, subTotal, dispatch, appliedDiscounts, discounts, userRemovedDiscounts]);

  return appliedDiscounts;
};

export default useDiscounts;
