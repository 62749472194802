import React from "react";
import hero1 from "../../assets/cover.jpg";
import "../styles.css";

function Carousel() {
  return (
    <>
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={hero1} className="cImg d-block w-100" alt="Cover Image" />

            <div className="overlay"></div>

            <div className="carousel-caption d-md-flex justify-content-center align-items-center">
              <div className="text-center">
                <h1 className="hero-heading">Bengal<br />Express</h1>

                <div className="hero-buttons row d-none d-md-flex justify-content-center">
                  <div className="col-md-auto">
                    <a href="/takeaway" className="hero-link" alt="Link to Order Food">
                      <button type="button" className="hero-button">Order Now</button>
                    </a>
                  </div>
                </div>

                <div className="d-md-none">
                  <a href="/takeaway" className="hero-link" alt="Link to Order Food">
                    <button type="button" className="hero-button">Order Now</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Scrolling Text */}
      <div className="scrolling-text-container">
        <div className="scrolling-text">
          <span>Get 15% off orders over £15! </span>
          <span>Get 15% off orders over £15! </span>
          <span>Get 15% off orders over £15! </span>
          <span>Get 15% off orders over £15! </span>
          <span>Get 15% off orders over £15! </span>
        </div>
      </div>
    </>
  );
}

export default Carousel;
