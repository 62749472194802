let lastId = 0;


// Remember that resetting cart is different to initial state.
const initialState = {
  transactionNo: "",
  transactionId: "",
  orderNo: null,
  orderId: null,
  items: [],
  customer: {},
  note: "",
  type: 0,
  time: "",
  table: {},
  payment: [],
  paymentType: 0,
  discount: {
    code: "",
    amount: 0,
    type: null,
  },
  automaticDiscounts: [],
  userRemovedDiscounts: [],
};

const cartData = (state = initialState, action) => {
    switch (action.type) {

        case 'ADD_ORDER_TO_CART': {
          return {
            ...state,
            orderNo: action.order.orderNo,
            note: action.order.note,
            type: action.order.type,
            time: action.order.timeSlot,
            customer: action.order.customer,
            items: action.orderDetails
          }
        }

        case 'ADD_TO_CART':
            return {...state,
              items: [...state.items, {
                id: ++lastId,
                pid: action.item.id,
                name: action.item.name,
                category: action.item.category,
                categoryName: action.item.categoryName,
                printer: action.item.printer,
                price: action.item.price,
                quantity: action.quantity,
                variant: action.variant,
                modifiers: action.modifiers,
                note: action.note,
                damount: 0,
                dtype: "",
              },
            ]}

            case 'ADD_GUEST_TO_CART':
              return {...state,
                customer: {
                  ...state.customer,
                  firstName: action.payload.firstName,
                  secondName: action.payload.secondName,
                  email: action.payload.email,
                  phone: action.payload.phone,
              }}

        case 'REMOVE_FROM_CART':
            return {
              ...state,
              items: state.items.filter(product => product.id !== action.payload.id)
            }

        case 'RESET_CART':
            return {
              ...state,
              transactionNo: "",
              transactionId: "",
              orderNo: null,
              orderId: null,
              items: [],
              note: "",
              time: "",
              table: {},
              payment: [],
              discount: {
                code: "",
                amount: 0,
                type: null,
              }
            }

        case 'INCREASE_QUANTITY':
          return {
            ...state,
            items: state.items.map(product => product.id === action.payload.id ?
                {...product, quantity: product.quantity + 1} : {...product, quantity: product.quantity})
          }

        case 'DECREASE_QUANTITY':
          return {
            ...state,
            items: state.items.map(product => product.id === action.payload.id && product.quantity > 1 ?
                {...product, quantity: product.quantity - 1} : {...product, quantity: product.quantity})
          }

        case 'ADD_NOTE':
          return {
            ...state,
            items: state.items.map(product => product.id === action.payload.id ?
                {...product, note: action.note} : {...product, note: product.note })
          }

          case 'ADD_DISCOUNT':
            return {
              ...state,
              items: state.items.map(product => product.id === action.payload.id ?
                  {...product, dtype: action.dtype, damount: action.damount} : {...product, dtype: product.dtype, damount: product.damount })
            }

        case 'FETCH_CART_LIST':
          return action.payload

        case 'ADD_CART_TO_DATABASE':
          return action.payload

          case 'ADD_CUSTOMER':
            return { ...state, customer: {
                firstName: action.payload.firstName,
                secondName: action.payload.secondName,
                phone: action.payload.phone,
                postcode: action.payload.postcode,
                line1: action.payload.line1,
                line2: action.payload.line2,
                town: action.payload.town,
                county: action.payload.county,
              }
            }
            case 'ADD_CUSTOMER_ADDRESS': {
              return {
                ...state, customer: {
                ...state.customer,
                postcode: action.payload.postcode,
                line1: action.payload.line1,
                line2: action.payload.line2,
                town: action.payload.town,
                county: action.payload.county,
                location: action.payload.location
              }
              }

            }
            case 'ADD_TABLE':
              return { ...state, table: {
                  id: action.payload.id,
                  name: action.payload.name
                }
              }
          case 'ADD_ORDER_TIME':
            return { ...state, time: action.payload }

          case 'ADD_ORDER_NOTE':
            return { ...state, note: action.payload }

            case 'ADD_ORDER_DISCOUNT':
              return { ...state, discount: {
                code: action.payload.code,
                amount: action.payload.amount,
                type: action.payload.type
               }
             }

          case 'ADD_ORDER_NO':
            return { ...state, orderNo: action.payload }

            case 'ADD_ORDER_ID':
              return { ...state, orderId: action.payload }

          case 'ADD_TRANSACTION_NO':
            return { ...state, transactionNo: action.payload }

          case 'UPDATE_ORDER_TYPE':
            return {...state, type: action.payload }


            case 'UPDATE_PAYMENT_TYPE':
              return {...state, paymentType: action.payload }

            case 'ADD_PAYMENT':
                return {...state,
                  payments: [...state.payments, {
                    name: action.payload.name,
                    amount: action.payload.amount,
                  }
                ]}
                case 'ADD_AUTOMATIC_DISCOUNT':
                  return {
                    ...state,
                    automaticDiscounts: [...state.automaticDiscounts, ...action.payload]
                  };
                case 'REMOVE_AUTOMATIC_DISCOUNT':
                  return {
                    ...state,
                    automaticDiscounts: state.automaticDiscounts.filter(
                      discount => !action.payload.some(toRemove => toRemove.id === discount.id)
                    )
                  };
            
                case 'ADD_USER_REMOVED_DISCOUNT':
                  return {
                    ...state,
                    userRemovedDiscounts: [...state.userRemovedDiscounts, action.payload]
                  };
            
                case 'REMOVE_USER_REMOVED_DISCOUNT':
                  return {
                    ...state,
                    userRemovedDiscounts: state.userRemovedDiscounts.filter(id => id !== action.payload)
                  };
            
                case 'CLEAR_USER_REMOVED_DISCOUNTS':
                  return {
                    ...state,
                    userRemovedDiscounts: []
                  };
        default:
          return state;
    }

    return state
}

export default cartData;
